import revive_payload_client_4sVQNw7RlN from "/usr/src/nuxt3-app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/nuxt3-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/nuxt3-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import browser_devtools_timing_client_tuAbkkkV0J from "/usr/src/nuxt3-app/node_modules/nuxt/dist/app/plugins/browser-devtools-timing.client.js";
import payload_client_yVLowv6hDl from "/usr/src/nuxt3-app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/src/nuxt3-app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/nuxt3-app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/nuxt3-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/usr/src/nuxt3-app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/nuxt3-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_X7whXyrIWR from "/usr/src/nuxt3-app/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/usr/src/nuxt3-app/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/usr/src/nuxt3-app/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/usr/src/nuxt3-app/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/usr/src/nuxt3-app/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/usr/src/nuxt3-app/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ZuYw5i1i7i from "/usr/src/nuxt3-app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import vuetify_7h9QAQEssH from "/usr/src/nuxt3-app/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  browser_devtools_timing_client_tuAbkkkV0J,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_ZuYw5i1i7i,
  vuetify_7h9QAQEssH
]